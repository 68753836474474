import React from 'react';

const ColorPicker = ({ containerClasses = '', onChange, color }) => {
  return (
    <div className={`flex items-center ${containerClasses}`}>
      <input
        className='cursor-pointer w-8 h-9 rounded-md'
        type={'color'}
        value={color}
        onChange={onChange}
      />
      <p className='text-gray-500 text-xs mx-2'>{color}</p>
    </div>
  );
};

export default ColorPicker;
