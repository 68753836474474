import React, { useContext } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import congesService from '@src/data/congesService';

import InputCustom from '@components/UI/InputCustom';
import ToastCustom from '@components/UI/ToastCustom';
import UserContext from '@context/UserContext';

const weekDayIds = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

const OpenDaysCheckbox = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const { setCurrentUser } = useContext(UserContext);

  const updateOpenDays = (day, isChecked) => {
    congesService
      .put('/v1/clients/open-days', { ...values.open_days, [day]: isChecked })
      .then((resp) => {
        setCurrentUser((prevState) => ({
          ...prevState,
          client_days: resp.data.data.map((e) => weekDayIds[e]),
        }));

        ToastCustom.validated(t('successUpdate'));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className='flex flex-wrap items-center gap-y-2 gap-x-1'>
      <InputCustom
        name='open_days.monday'
        id='open_days.monday'
        type='checkbox'
        value={values.open_days.monday}
        label={t('monday')}
        labelClass='text-sm'
        onChange={(e) => {
          updateOpenDays('monday', e.target.checked);
        }}
      />
      <InputCustom
        name='open_days.tuesday'
        id='open_days.tuesday'
        type='checkbox'
        label={t('tuesday')}
        labelClass='text-sm'
        value={values.open_days.tuesday}
        onChange={(e) => {
          updateOpenDays('tuesday', e.target.checked);
        }}
      />
      <InputCustom
        name='open_days.wednesday'
        id='open_days.wednesday'
        type='checkbox'
        label={t('wednesday')}
        labelClass='text-sm'
        value={values.open_days.wednesday}
        onChange={(e) => {
          updateOpenDays('wednesday', e.target.checked);
        }}
      />
      <InputCustom
        name='open_days.thursday'
        id='open_days.thursday'
        type='checkbox'
        value={values.open_days.thursday}
        onChange={(e) => {
          updateOpenDays('thursday', e.target.checked);
        }}
        label={t('thursday')}
        labelClass='text-sm'
      />
      <InputCustom
        name='open_days.friday'
        id='open_days.friday'
        type='checkbox'
        value={values.open_days.friday}
        onChange={(e) => {
          updateOpenDays('friday', e.target.checked);
        }}
        label={t('friday')}
        labelClass='text-sm'
      />
      <InputCustom
        name='open_days.saturday'
        id='open_days.saturday'
        type='checkbox'
        value={values.open_days.saturday}
        onChange={(e) => {
          updateOpenDays('saturday', e.target.checked);
        }}
        label={t('saturday')}
        labelClass='text-sm'
      />
      <InputCustom
        name='open_days.sunday'
        id='open_days.sunday'
        type='checkbox'
        value={values.open_days.sunday}
        onChange={(e) => {
          updateOpenDays('sunday', e.target.checked);
        }}
        label={t('sunday')}
        labelClass='text-sm'
      />
    </div>
  );
};

export default OpenDaysCheckbox;
