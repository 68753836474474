import React from 'react';
import InputCustom from '../../../UI/InputCustom';

function CustomInputNumber({
  id,
  value,
  handleChange,
  containerClass = '',
  disabled = false,
}) {
  return (
    <InputCustom
      id={id}
      name={id}
      disabled={disabled}
      type='number'
      containerClass={`w-16 ${containerClass} `}
      labelClass='h-6'
      inputClass='h-8 w-16 text-center'
      value={value}
      onChange={(e) => {
        if (e.target.value > 9999) return 9999;
        handleChange(e);
      }}
    />
  );
}

export default CustomInputNumber;
