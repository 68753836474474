import React, { useEffect } from 'react';

import InputCustom from '@components/UI/InputCustom';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { ButtonFilled } from '@components/UI/Buttons';

import { faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { v4 as uuidv4 } from 'uuid';

const MaxConsecutiveDays = ({ selectedLeaveType, setSelectedLeaveType }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const createLeaveTypeSubFamily = () => {
    const tempSubFamilies = {
      ...selectedLeaveType,
      leave_type_sub_families: [
        ...selectedLeaveType.leave_type_sub_families,
        {
          leave_type_id: selectedLeaveType.id,
          name: '',
          value: 0,
          id: uuidv4(),
          absence_reason: '',
        },
      ],
    };
    setSelectedLeaveType(tempSubFamilies);
  };

  return (
    !selectedLeaveType.is_monthly && (
      <div className='ml-7'>
        <ButtonFilled
          icon={
            <FontAwesomeIcon icon={faPlus} className='text-lightgrey-900' />
          }
          buttonClass='my-9'
          flat={false}
          onClick={createLeaveTypeSubFamily}
        >
          {t('addLeaveTypeSubFamily')}
        </ButtonFilled>
        <div className='my-3 flex flex-col mr-7'>
          {selectedLeaveType.leave_type_sub_families?.map(
            (subFamilyType, index) => (
              <div
                key={index}
                className='flex gap-x-7 my-2 items-end justify-between'
              >
                <InputCustom
                  name={`leave_type_sub_families[${index}].name`}
                  label={t('label')}
                  id={`leave_type_sub_families[${index}].name`}
                  type='text'
                  value={selectedLeaveType.leave_type_sub_families[index]?.name}
                  onChange={(e) => {
                    const tempSubFamilies = [
                      ...selectedLeaveType.leave_type_sub_families,
                    ];
                    tempSubFamilies[index].name = e.target.value;
                    setFieldValue(
                      `leave_type_sub_families[${index}].name`,
                      e.target.value
                    );
                    setSelectedLeaveType((prevState) => ({
                      ...prevState,
                      leave_type_sub_families: tempSubFamilies,
                    }));
                  }}
                />
                <InputCustom
                  name={`leave_type_sub_families[${index}].value`}
                  label={t('consecutiveDaysNumber')}
                  id={`leave_type_sub_families[${index}].value`}
                  type='number'
                  value={
                    selectedLeaveType.leave_type_sub_families[index]?.value
                  }
                  onChange={(e) => {
                    const tempSubFamilies = [
                      ...selectedLeaveType.leave_type_sub_families,
                    ];
                    tempSubFamilies[index].value =
                      e.target.value !== '' ? parseInt(e.target.value) : '';

                    setFieldValue(
                      `leave_type_sub_families[${index}].value`,
                      e.target.value
                    );
                    setSelectedLeaveType((prevState) => ({
                      ...prevState,
                      leave_type_sub_families: tempSubFamilies,
                    }));
                  }}
                />

                <InputCustom
                  name={`leave_type_sub_families[${index}].absence_reason`}
                  id={`leave_type_sub_families[${index}].absence_reason`}
                  label={t('leaveCode')}
                  type='text'
                  value={
                    selectedLeaveType.leave_type_sub_families[index]
                      ?.absence_reason
                  }
                  onChange={(e) => {
                    const tempSubFamilies = [
                      ...selectedLeaveType.leave_type_sub_families,
                    ];
                    tempSubFamilies[index].absence_reason = e.target.value;
                    setSelectedLeaveType((prevState) => ({
                      ...prevState,
                      leave_type_sub_families: tempSubFamilies,
                    }));
                  }}
                />
                <button
                  type='button'
                  className='mb-2'
                  onClick={() => {
                    const newSubfamiliesList =
                      selectedLeaveType.leave_type_sub_families.filter(
                        (e) => e.id !== subFamilyType.id
                      );
                    setSelectedLeaveType((prevState) => ({
                      ...prevState,
                      leave_type_sub_families: newSubfamiliesList,
                    }));
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    size='lg'
                    className='text-gray-500'
                  />
                </button>
              </div>
            )
          )}
        </div>
      </div>
    )
  );
};

export default MaxConsecutiveDays;
