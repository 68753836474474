import React from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import InputCustom from '@src/components/UI/InputCustom';

import dayjs from 'dayjs';
import InfoTooltip from './ui/InfoTooltip';
import CustomInputNumber from './ui/CustomInputNumber';
import CustomChexbox from './ui/CustomChexbox';

const AdvancedSettings = ({ selectedLeaveType, setSelectedLeaveType }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();
  const {
    is_monthly,
    order_appearance,
    default_leave_value,
    increment_days_number,
    is_auto_increment_active,
    end_date,
    start_date,
    is_ignore_by_export,
    can_justify_later,
    is_attachment_required,
    is_pay,
    id,
    is_half_day,
    can_exceed,
  } = selectedLeaveType;
  return (
    <div className='w-full mt-2'>
      <h3>{t('advancedSettings')}</h3>
      <div className='flex flex-col gap-y-5 mt-2'>
        {is_monthly && (
          <div className='flex items-center justify-between relative'>
            <div className='flex items-center'>
              <CustomChexbox
                handleChange={(e) => {
                  setSelectedLeaveType({
                    ...selectedLeaveType,
                    order_appearance: e.target.checked ? 1 : 0,
                  });
                }}
                id={'order_appearanceToggler'}
                checked={order_appearance > 0}
                labelName={t('orderAppearance')}
              />
              <InfoTooltip title={t('orderAppearanceTooltip')} />
            </div>
            <CustomInputNumber
              handleChange={(e) => {
                if (e.target.value > 9999) return 9999;
                setFieldValue('order_appearance', e.target.value);
                setSelectedLeaveType({
                  ...selectedLeaveType,
                  order_appearance: parseInt(e.target.value),
                });
              }}
              containerClass='absolute right-0'
              id={'order_appearance'}
              value={order_appearance ?? 0}
            />
          </div>
        )}

        <div className='flex items-center justify-between relative'>
          <CustomChexbox
            checked={default_leave_value > 0}
            handleChange={(e) => {
              const defaultVAlue = default_leave_value > 0 ? 0 : 1;
              setSelectedLeaveType({
                ...selectedLeaveType,
                default_leave_value: defaultVAlue,
              });
            }}
            id={'max_consecutive_days'}
            labelName={t('maxConsecutiveDays')}
          />
          <CustomInputNumber
            handleChange={(e) => {
              setSelectedLeaveType({
                ...selectedLeaveType,
                default_leave_value: e.target.value,
              });
            }}
            containerClass='absolute right-0'
            id={'default_leave_value'}
            value={default_leave_value ?? 0}
          />
        </div>
        {is_monthly && (
          <div className='flex items-center'>
            <CustomChexbox
              checked={can_exceed}
              handleChange={(e) => {
                setFieldValue('can_exceed', e.target.checked);
                setSelectedLeaveType({
                  ...selectedLeaveType,
                  can_exceed: e.target.checked,
                });
              }}
              id={'can_exceed'}
              labelName={t('canExceed')}
            />

            <InfoTooltip title={t('canExceedTooltip')} />
          </div>
        )}
        <CustomChexbox
          checked={is_half_day}
          handleChange={(e) => {
            const value = e.target.checked;
            setFieldValue('is_half_day', value);
            setSelectedLeaveType({
              ...selectedLeaveType,
              is_half_day: value,
            });
          }}
          id={'is_half_day'}
          labelName={t('halfDayPossible')}
        />

        {is_monthly && !id && (
          <CustomChexbox
            checked={is_pay ?? false}
            handleChange={(e) => {
              setFieldValue('is_pay', e.target.checked);
              setSelectedLeaveType({
                ...selectedLeaveType,
                is_pay: e.target.checked,
              });
            }}
            id={'is_pay'}
            labelName={t('counterLastYear')}
            disabled={is_monthly && id}
          />
        )}
        <div className='flex gap-2'>
          <CustomChexbox
            checked={is_attachment_required}
            handleChange={(e) => {
              const value = e.target.checked;
              setFieldValue('is_attachment_required', value);
              setFieldValue('can_justify_later', value);
              setSelectedLeaveType({
                ...selectedLeaveType,
                is_attachment_required: value,
                can_justify_later: value,
              });
            }}
            id={'is_attachment_required'}
            labelName={t('isAttachmentRequired')}
          />
          {is_attachment_required && (
            <div className='flex items-center'>
              <CustomChexbox
                checked={can_justify_later ?? false}
                handleChange={(e) => {
                  setFieldValue('is_attachment_required', true);
                  setFieldValue('can_justify_later', e.target.checked);
                  setSelectedLeaveType({
                    ...selectedLeaveType,
                    is_attachment_required: true,
                    can_justify_later: e.target.checked,
                  });
                }}
                id={'can_justify_later'}
                labelName={t('can_justify_later')}
              />
              <InfoTooltip title={t('canJustifyLaterTooltip')} />
            </div>
          )}
        </div>
        <div className='flex items-center'>
          <CustomChexbox
            checked={is_ignore_by_export}
            handleChange={(e) => {
              setFieldValue('is_ignore_by_export', e.target.checked);
              setSelectedLeaveType({
                ...selectedLeaveType,
                is_ignore_by_export: e.target.checked,
              });
            }}
            id={'is_ignore_by_export'}
            labelName={t('ignoreByExport')}
          />

          <InfoTooltip title={t('ignoreByExportTooltip')} />
        </div>
        <div className='flex items-center'>
          <CustomChexbox
            checked={start_date || end_date}
            handleChange={(e) => {
              setSelectedLeaveType({
                ...selectedLeaveType,
                start_date: start_date ? '' : dayjs().format('YYYY-MM-DD'),
                end_date: end_date ? '' : dayjs().format('YYYY-MM-DD'),
              });
              setFieldValue('isDefined', e.target.checked);
            }}
            id={'isDefined'}
            labelName={t('leaveLimitedPeriod')}
          />

          <InfoTooltip title={t('isMonthlyTooltip')} />
        </div>
        {start_date && (
          <div className='flex gap-x-6'>
            <InputCustom
              name='start_date'
              id='start_date'
              type='datePicker'
              dateValue={start_date}
              label={t('startDate')}
              onChange={(value) => {
                const formatedValue = dayjs(value).format('YYYY-MM-DD');
                setFieldValue('start_date', formatedValue);
                setSelectedLeaveType({
                  ...selectedLeaveType,
                  start_date: formatedValue,
                });
              }}
            />
            <InputCustom
              name='end_date'
              id='end_date'
              type='datePicker'
              dateValue={end_date}
              label={t('endDate')}
              onChange={(value) => {
                const formatedValue = dayjs(value).format('YYYY-MM-DD');
                setFieldValue('end_date', formatedValue);
                setSelectedLeaveType({
                  ...selectedLeaveType,
                  end_date: formatedValue,
                });
              }}
            />
          </div>
        )}
        <div className='flex items-center h-12'>
          <InputCustom
            name='personaliseCountLeave'
            id='personaliseCountLeave'
            type='switch'
            label={t('personaliseCountLeave')}
            labelClass='text-sm ml-3'
            containerClass='flex relative items-center cursor-pointer flex-row'
            checked={is_auto_increment_active}
            onChange={() => {
              setSelectedLeaveType((prevState) => ({
                ...prevState,
                is_auto_increment_active: !prevState.is_auto_increment_active,
              }));
            }}
          />
          {is_auto_increment_active && (
            <InputCustom
              id='countPersonnalise'
              name='countPersonnalise'
              type='number'
              containerClass='w-16 mb-1'
              labelClass='h-6'
              inputClass='w-16 h-10 ml-5'
              value={increment_days_number}
              onChange={(e) => {
                increment_days_number = parseFloat(e.target.value);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdvancedSettings;
