import React from 'react';
import InputCustom from '../../../UI/InputCustom';

function CustomChexbox({
  id,
  disabled = false,
  checked,
  labelName,
  handleChange,
}) {
  return (
    <InputCustom
      name={id}
      id={id}
      type='checkbox'
      disabled={disabled}
      checked={checked}
      label={labelName}
      labelClass='text-sm'
      onChange={handleChange}
    />
  );
}

export default CustomChexbox;
