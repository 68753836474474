import React from 'react';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

function InfoTooltip({ title, placement = 'top' }) {
  return (
    <Tooltip title={title} arrow placement={placement}>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className='cursor-pointer text-lightgrey-500'
      />
    </Tooltip>
  );
}

export default InfoTooltip;
