import React from 'react';

import {
  faLock,
  faSearch,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputCustom from '@components/UI/InputCustom';
import { useTranslation } from 'react-i18next';

import { classNames } from '@utility/Utils';
import { useFormikContext } from 'formik';

const LeaveTypesList = ({
  handleSearch,
  leaveTypes,
  setSelectedLeaveType,
  handleDelete,
  selectedLeaveType,
}) => {
  const { t } = useTranslation();

  const { resetForm, dirty } = useFormikContext();

  return (
    <div className='w-72'>
      <div className='px-2'>
        <InputCustom
          type='inputIcon'
          id='searchLeaveType'
          name='searchLeaveType'
          className='cursor-text'
          icon={faSearch}
          placeholder={t('search')}
          onChange={handleSearch}
        />
      </div>
      <div className='mt-3 py-3 rounded-lg h-72 overflow-y-auto vertical-scrollbar'>
        {leaveTypes.length > 0 &&
          leaveTypes.map((leaveType) => (
            <div
              key={leaveType.id}
              className={`p-3 flex items-center cursor-pointer ${
                leaveType.id === selectedLeaveType.id
                  ? 'bg-lightgrey-800 text-white font-medium'
                  : 'text-secondarylight font-light'
              }`}
              onClick={() => {
                if (dirty) {
                  resetForm();
                }
                setSelectedLeaveType(leaveType);
              }}
            >
              <div
                className='w-1 h-6 rounded-3xl mr-2 overflow-x-hidden text-ellipsis'
                style={{
                  backgroundColor: leaveType.color?.replace(/0xFF|0xff/, '#'),
                }}
              />
              <span className='text-sm leading-6'>{leaveType.name}</span>
              {!leaveType.is_deletable ? (
                <FontAwesomeIcon
                  icon={faLock}
                  className={classNames(
                    leaveType.id === selectedLeaveType.id
                      ? 'text-white'
                      : 'text-lightgrey-800',
                    'ml-auto'
                  )}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  onClick={() => handleDelete(leaveType)}
                  className={classNames(
                    leaveType.id === selectedLeaveType.id
                      ? 'text-white'
                      : 'text-lightgrey-800',
                    'ml-auto'
                  )}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default LeaveTypesList;
